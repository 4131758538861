import React from 'react'
import { Downloads as DownloadsCmp } from '../components/Downloads'
import { Root } from '../components/Root'
import { Card } from '../ui/cards'

const Downloads = () => (
  <Root>
    <h2>Downloads</h2>
    <Card>
      <DownloadsCmp />
    </Card>
  </Root>
)

export default Downloads
